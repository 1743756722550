import React, {useCallback, useEffect, useRef, useState} from 'react';
import './App.css';
import TopBar from "./component/TopBar";
import AppRoutes from "./AppRoutes";
import {AppContext} from "./common/context";
import {Amplify, Auth} from "aws-amplify";
import {createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider,} from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import VrexApi from "./api/vrexApi";
import {Alert, CssBaseline, Dialog, Snackbar, useMediaQuery} from "@mui/material";
import Background from './images/background.jpg'
import {getToken} from "./api/vrexAuth";
import LiveUpdates from "./common/liveUpdates";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {I18nextProvider, useTranslation} from 'react-i18next';
import i18n from './i18n'
import IntegrationsApi from "./api/integrationsApi";
import {Userpilot} from 'userpilot'
import {useLocation} from "react-router-dom";

Userpilot.initialize(process.env.REACT_APP_USERPILOT_ID);

Amplify.configure({
    Auth: {
        region: 'eu-central-1',
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
});

const useStyles = makeStyles(() => ({
    root: theme => ({
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundBlendMode: "soft-light",
        backgroundColor: theme.palette.mode === "dark" ? "#181818" : "#989898",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
    }),
}));

export default function App() {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [authorizations, setAuthorizations] = useState([]);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [user, setUser] = useState(null);
    const [vrexUrl, setVrexUrl] = useState("")
    const [nwPluginUrl, setNwPluginUrl] = useState("")
    const prefersDarkModeSystem = useMediaQuery('(prefers-color-scheme: dark)');
    const storedAppTheme = localStorage.getItem("appTheme") || "auto";
    const [appTheme, setAppTheme] = useState(storedAppTheme);
    const [toast, setToast] = useState(null);
    const [liveUpdatesReady, setLiveUpdatesReady] = useState(false);
    const [liveUpdatesClosed, setLiveUpdatesClosed] = useState(false);
    const liveUpdates = useRef(new LiveUpdates());
    const {t} = useTranslation();
    const location = useLocation();

    const darkMode = React.useMemo(() => {
        return appTheme === "auto" ? prefersDarkModeSystem : appTheme === "dark";
    }, [appTheme, prefersDarkModeSystem]);

    const theme = React.useMemo(
        () => {
            const tt = createTheme();
            let t = createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                    primary: {
                        main: "#4788bf"
                    },
                },
                components: {
                    MuiPopover: {
                        styleOverrides: {
                            paper: {
                                backgroundImage: "none"
                            }
                        }
                    },
                    MuiDialog: {
                        styleOverrides: {
                            paper: {
                                borderRadius: 0,
                                width: "400px",
                                backgroundImage: "none"
                            },
                        }
                    },
                    MuiDialogTitle: {
                        styleOverrides: {
                            root: {
                                padding: tt.spacing(3),
                                paddingTop: tt.spacing(2),
                                paddingBottom: tt.spacing(1),
                                textTransform: "uppercase",
                            }
                        }
                    },
                    MuiDialogContent: {
                        styleOverrides: {
                            root: {
                                paddingTop: tt.spacing(1),
                                paddingBottom: tt.spacing(1),
                                paddingLeft: tt.spacing(3),
                                paddingRight: tt.spacing(3),
                            }
                        }
                    },
                    MuiButton: {
                        defaultProps: {
                            disableElevation: true,
                        },
                        styleOverrides: {
                            root: {
                                borderRadius: 0,
                            }
                        }
                    },
                    MuiToggleButton: {
                        styleOverrides: {
                            root: {
                                borderRadius: 0,
                            },
                        }
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            root: {
                                borderRadius: 0,
                            }
                        }
                    },
                    MuiAccordion: {
                        styleOverrides: {
                            root: {
                                backgroundImage: "none"
                            }
                        }
                    },
                    MuiAccordionSummary: {
                        styleOverrides: {
                            content: {
                                alignItems: "center",
                            }
                        }
                    },
                    MuiDropzoneArea: {
                        styleOverrides: {
                            root: {
                                borderRadius: 0,
                            }
                        }
                    }
                }
            });
            if (darkMode) {
                t.palette.background.list = '#363636';
                t.palette.background.listAlt = '#383838';
                t.palette.background.default = '#303030';
                t.palette.background.paper = '#424242';
            } else {
                t.palette.background.list = '#EEEEEE';
                t.palette.background.listAlt = '#F5F5F5';
            }
            t.palette.secondary = t.palette.augmentColor({
                color: {
                    main: t.palette.text.primary,
                }
            })
            t.palette.tertiary = t.palette.augmentColor({
                color: {
                    main: '#383838',
                }
            })
            return t;
        },
        [darkMode],
    );
    const classes = useStyles(theme);

    useEffect(() => {
        async function onLoad() {
            let authed = await authenticate();
            userHasAuthenticated(authed);
            setIsAuthenticating(false);
        }

        onLoad().then();
    }, []);

    useEffect(() => {
        async function onLoad() {
            try {
                const response = await fetch(process.env.REACT_APP_VREX_DOWNLOAD_URL, {headers: {"Cache-Control": "no-cache"}});
                let lines = (await response.text()).split("\n");
                setVrexUrl(lines[1]);
            } catch (e) {
                setToast({message: t('downloads.error'), error: e, severity: "error"})
            }
        }

        onLoad().then();
    }, [setToast, t])

    useEffect(() => {
        async function onLoad() {
            try {
                const response = await fetch(process.env.REACT_APP_NW_PLUGIN_DOWNLOAD_URL, {headers: {"Cache-Control": "no-cache"}});
                let lines = (await response.text()).split("\n");
                setNwPluginUrl(lines[1]);
            } catch (e) {
                setToast({message: t('downloads.error'), error: e, severity: "error"})
            }
        }

        onLoad().then();
    }, [setToast, t])

    const refreshUser = useCallback(async () => {
        try {
            let user = await VrexApi.getUser();
            setUser(user);
        } catch (e) {
            setToast({message: "Failed getting user ", error: e, severity: "error"})
        }
    }, []);

    useEffect(() => {
        return liveUpdates.current.subscribeToEventLocally("USER_INVITED", refreshUser)
    }, [refreshUser])

    useEffect(() => {
        async function onLoad() {
            function onAuthenticated() {
                setLiveUpdatesReady(true);
            }

            function onClosed() {
                setLiveUpdatesReady(false);
                setLiveUpdatesClosed(true);
            }

            if (isAuthenticated) {
                let token = await getToken();
                liveUpdates.current.connect(process.env.REACT_APP_WEBSOCKET_URL, token, onAuthenticated, onClosed);
                await Promise.all([refreshUser(), getAuthorizations()]);
            } else {
                setLiveUpdatesReady(false);
                setLiveUpdatesClosed(false);
                liveUpdates.current.close();
                setUser(null);
                setAuthorizations([]);
            }
        }

        onLoad().then();
    }, [isAuthenticated, refreshUser])

    useEffect(() => {
        if (user) {
            let orgId = -1;
            let role = "none";
            let orgName = "none";
            if (user.organizations.length > 0) {
                orgId = user.organizations[0].id;
                orgName = user.organizations[0].name;
                role = user.organizations[0].role.name;
            }
            let parameters = {
                email: user.email,
                name: `${user.name} ${user.lastName}`,
                role: role,
                company: {
                    name: orgName,
                    id: orgId,
                }
            }
            Userpilot.identify(user.id, parameters);
        }
    }, [user])

    useEffect(() => {
        Userpilot.reload();
    }, [location])

    const subscribeToEventLocally = useCallback((event, callback) => {
        return liveUpdates.current.subscribeToEventLocally(event, callback);
    }, []);

    const subscribeToProject = useCallback((projectId) => {
        liveUpdates.current.subscribeToProject(projectId);
    }, []);

    async function authenticate() {
        try {
            await Auth.currentSession();
            return true;
        } catch (e) {
            if (e !== 'No current user') {
                setToast({message: "Failed getting auth session ", error: e, severity: "error"})
            }
            return false;
        }
    }

    async function getAuthorizations() {
        try {
            let authorizations = await IntegrationsApi.getAuthorizations();
            setAuthorizations(authorizations);
        } catch (e) {

        }
    }

    function onAppThemeChanged(newAppTheme) {
        localStorage.setItem("appTheme", newAppTheme);
        setAppTheme(newAppTheme);
    }

    function renderToast() {
        function handleClose(event, reason) {
            if (reason === 'clickaway') {
                return;
            }
            setToast(null);
        }

        function printError(error) {
            if (!error) {
                return "";
            }
            let message = error.toString();
            if (!(error instanceof Error) && error.message) {
                message = error.message;
            }
            return ": " + message;
        }

        return (
            toast && (
                <Snackbar open={Boolean(toast)} autoHideDuration={10000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={toast.severity}>
                        {toast.message + printError(toast.error)}
                    </Alert>
                </Snackbar>)
        );
    }

    function renderIdleDialog() {
        return <Dialog
            open={liveUpdatesClosed && process.env.REACT_APP_LOCAL_API === "false"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('idle.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('idle.description')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => window.location.reload()} variant={"contained"} color="primary">
                    {t('idle.submit')}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    return !isAuthenticating &&
        <I18nextProvider i18n={i18n}>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <AppContext.Provider value={{
                        isAuthenticated, userHasAuthenticated,
                        authorizations, setAuthorizations,
                        user, setUser, refreshUser,
                        setToast,
                        liveUpdatesReady,
                        subscribeToEventLocally,
                        subscribeToProject,
                        vrexUrl,
                        nwPluginUrl,
                    }}>
                        <CssBaseline/>
                        {renderToast()}
                        {renderIdleDialog()}
                        <div className={classes.root}>
                            <TopBar appTheme={appTheme} onAppThemeChanged={onAppThemeChanged}/>
                            <AppRoutes/>
                        </div>
                    </AppContext.Provider>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </I18nextProvider>;
}
